import { Link } from "gatsby"
import React, { useCallback } from "react"
import styled from "styled-components"

import { useGetAssociateProgress, useUser } from "../../auth/hooks"
import {
  PageGuard,
  PageNotificationBanner,
  PageHeader,
  Table as TableBase,
} from "../../components"
import Seo from "../../components/seo"
import { TableHeading } from "../../components/Table"

const Table = styled(TableBase)`
  margin: 20px 0;

  .table-row {
    grid-template-areas:
      "associate associate connected"
      "associate associate active"
      "associate associate tpv"
      "earnings yourCut .";
    align-items: flex-start;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      grid-template-areas: "associate connected active tpv earnings yourCut";
      align-items: center;
    }
  }
`

const AssociateContainer = styled.div`
  grid-area: associate;

  .associate-email {
    font-weight: 500;
    line-height: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .associate-name {
    font-size: 12px;
    line-height: 22px;
  }
`

const RowTextField = styled.p<{
  gridArea: string
}>`
  grid-area: ${({ gridArea }) => gridArea};
  display: flex;
  padding-right: 5px;
  text-align: left;
  gap: 5px;

  .value {
    font-weight: 500;
  }

  .title {
    display: block;
    color: ${({ theme }) => theme.palette.bombay};
    font-size: 12px;
  }

  .quantity {
    align-self: flex-end;
    font-size: 12px;
    color: ${({ theme }) => theme.palette.blue};
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    .title {
      display: none;
    }
  }
`

const AssociateProgress = (): React.ReactElement => {
  const { user } = useUser()
  const [data, loading, fetchMore] = useGetAssociateProgress()

  const associates = data?.edges.map((associate) => {
    return associate.node
  })

  const handleFetchMoreAssociates = useCallback(() => {
    fetchMore({
      variables: {
        after: data?.pageInfo.endCursor,
      },
    })
  }, [fetchMore, data?.pageInfo.endCursor])

  const tableHeadings: TableHeading[] = [
    {
      name: "Associates",
      accessor: "userDetails",
      columnAllowance: "2fr",
      renderData: (
        index: number,
        details: {
          name: string
          email: string
        }
      ) => {
        return (
          <AssociateContainer key={`associate-${details.name}`}>
            <p className="associate-email">{details.email}</p>
            <p className="associate-name">{details.name}</p>
          </AssociateContainer>
        )
      },
    },
    {
      name: "Connected",
      accessor: "connected",
      columnAllowance: "1fr",
      renderData: (
        index: number,
        connected: Lookups.ProgressStatisticField
      ) => (
        <RowTextField gridArea="connected" key={`connected-${index}`}>
          <p className="title">Connected: </p>
          <p className="value">R{connected.amount}</p>
          <p className="quantity">({connected.quantity})</p>
        </RowTextField>
      ),
    },
    {
      name: "Active",
      accessor: "active",
      columnAllowance: "1fr",
      renderData: (index: number, active: Lookups.ProgressStatisticField) => (
        <RowTextField gridArea="active" key={`active-${index}`}>
          <p className="title">Active: </p>
          <p className="value">R{active.amount}</p>
          <p className="quantity">({active.quantity})</p>
        </RowTextField>
      ),
    },
    {
      name: "TPV",
      accessor: "tpv",
      columnAllowance: "1fr",
      renderData: (index: number, tpv: Lookups.ProgressStatisticField) => (
        <RowTextField gridArea="tpv" key={`tpv-${index}`}>
          <p className="title">TPV: </p>
          <p className="value">R{tpv.amount}</p>
          <p className="quantity">({tpv.quantity})</p>
        </RowTextField>
      ),
    },
    {
      name: "Earnings",
      accessor: "totalAmount",
      columnAllowance: "1fr",
      renderData: (index: number, totalAmount: number) => (
        <RowTextField gridArea="earnings" key={`earnings-${index}`}>
          <p className="title">Earnings: </p>
          <p className="value">R{totalAmount}</p>
        </RowTextField>
      ),
    },
    {
      name: "Your Cut",
      accessor: "totalPrincipalCut",
      columnAllowance: "1fr",
      renderData: (index: number, totalPrincipalCut: number) => (
        <RowTextField gridArea="yourCut" key={`yourCut-${index}`}>
          <p className="title">Your cut: </p>
          <p className="value">R{totalPrincipalCut}</p>
        </RowTextField>
      ),
    },
  ]

  return (
    <PageGuard
      verifyUserProfile
      allowedProfileTypes={["ASSOCIATE", "PRINCIPAL"]}
    >
      <Seo title="Reseller progress" />
      <PageHeader
        cypressTestId="reseller-progress-page-header"
        title="Reseller progress"
      />
      <PageNotificationBanner>
        {user?.profile?.verificationStatus !== "SUCCESSFUL" ? (
          <p>
            Your identity has not been verified yet. Please{" "}
            <Link className="highlighted" to="/onfido/">
              verify your identity
            </Link>{" "}
            to list your Resellers&apos; current progress.
          </p>
        ) : (
          <p>View your Resellers&apos; current progress.</p>
        )}
      </PageNotificationBanner>

      {user?.profile?.verificationStatus === "SUCCESSFUL" ? (
        <Table
          headings={tableHeadings}
          rowData={associates}
          loading={loading}
          emptyTableText="You have no associated Resellers for which reports can be generated"
          allRowsLoaded={!data?.pageInfo.hasNextPage}
          handleLoadMoreRows={handleFetchMoreAssociates}
        />
      ) : null}
    </PageGuard>
  )
}

export default AssociateProgress
